import TomSelect from "tom-select";
import { TomSettings } from "tom-select/dist/types/types";

import EditorJS from "@editorjs/editorjs";
import editorParser from "editorjs-html";
import Header from "@editorjs/header";
import List from "@editorjs/list";

declare var window: any;

const Hooks = {
  Select: {},
  DatePicker: {},
  Accordion: {},
  Dropdown: {},
  Dismiss: {},
  Tooltip: {},
  Filter: {},
  Search: {},
  EditorJS: {},
};

Hooks.Accordion = {
  active: null,
  mounted(this: any) {
    this.el.querySelectorAll("[aria-expanded='true']").forEach((btn: any) => {
      window.liveSocket.execJS(btn, btn.getAttribute("data-exec-open"));
    });

    this.el.addEventListener("collapse_accordion", (e: any) => {
      const dispatcher = e.detail.dispatcher;
      const index = dispatcher.getAttribute("data-button-index");
      const expanded = dispatcher.getAttribute("aria-expanded") === "true";
      expanded ? (this.active = null) : (this.active = index);

      this.el.querySelectorAll("[data-button-index]").forEach((btn: any) => {
        const index = btn.getAttribute("data-button-index");
        const execOpen = btn.getAttribute("data-exec-open");
        const execClose = btn.getAttribute("data-exec-close");

        if (this.active == index) {
          window.liveSocket.execJS(btn, execOpen);
        } else {
          window.liveSocket.execJS(btn, execClose);
        }
      });
    });

    this.el.addEventListener("open_accordion", (e: any) => {
      const dispatcher = e.detail.dispatcher;
      const expanded = dispatcher.getAttribute("aria-expanded") === "true";
      const execOpen = dispatcher.getAttribute("data-exec-open");
      const execClose = dispatcher.getAttribute("data-exec-close");

      expanded
        ? window.liveSocket.execJS(dispatcher, execClose)
        : window.liveSocket.execJS(dispatcher, execOpen);
    });
  },
};

// Converted as hook from apps/admin_web/assets/node_modules/flowbite/src/components/dropdown.js
Hooks.Dropdown = {
  dropdownInstance: null,
  default: {
    placement: "bottom",
    triggerType: "click",
    onShow: () => {},
    onHide: () => {},
  },
  mounted(this: any) {
    const targetEl = this.el;
    const triggerEl = document.querySelector(
      `[data-dropdown-toggle='${targetEl.id}']`,
    );
    const placement = triggerEl?.getAttribute("data-dropdown-placement");
    const onShow = targetEl.getAttribute("data-dropdown-on-show");
    const onHide = targetEl.getAttribute("data-dropdown-on-hide");
    const dropdownInstance = new window.Dropdown(targetEl, triggerEl, {
      placement: placement ? placement : this.default.placement,
      onShow: onShow
        ? () => window.liveSocket.execJS(targetEl, onShow)
        : this.default.onShow,
      onHide: onHide
        ? () => window.liveSocket.execJS(targetEl, onHide)
        : this.default.onHide,
    });
    this.dropdownInstance = dropdownInstance;

    this.handleEvent("show_dropdown", ({ id }: any) => {
      id === this.el.id && dropdownInstance.show();
    });

    this.el.addEventListener("hide_dropdown", () => {
      dropdownInstance.hide();
    });
  },
  updated(this: any) {
    this.dropdownInstance.destroyAndRemoveInstance();
    this.mounted();
  },
};

// Converted as hook from apps/admin_web/assets/node_modules/flowbite/src/components/dismiss.js
Hooks.Dismiss = {
  mounted(this: any) {
    const triggerEl = this.el;
    const targetEl = document.querySelector(
      triggerEl.getAttribute("data-dismiss-target"),
    );
    new window.Dismiss(targetEl, { triggerEl });
  },
};

// Converted as hook from apps/admin_web/assets/node_modules/flowbite/src/components/tooltip.js
Hooks.Tooltip = {
  default: {
    placement: "top",
    triggerType: "hover",
    onShow: () => {},
    onHide: () => {},
  },
  mounted(this: any) {
    const targetEl = this.el;
    const triggerEl = document.querySelector(
      `[data-tooltip-target='${this.el.id}']`,
    );
    const triggerType = triggerEl?.getAttribute("data-tooltip-trigger");
    const placement = triggerEl?.getAttribute("data-tooltip-placement");
    const onShow = targetEl.getAttribute("data-tooltip-on-show");

    new window.Tooltip(targetEl, triggerEl, {
      placement: placement ? placement : this.default.placement,
      triggerType: triggerType ? triggerType : this.default.triggerType,
      onShow: onShow
        ? () => window.liveSocket.execJS(targetEl, onShow)
        : this.default.onShow,
    });
  },
  updated(this: any) {
    // re-initiate to re-create popper instance
    this.mounted();
  },
};

Hooks.Select = {
  remoteSettings(remoteUrl: string, queryField: string): any {
    return {
      maxOptions: null,
      firstUrl: function (query: any) {
        return `${remoteUrl}?${queryField}=${encodeURIComponent(query)}`;
      },
      load: function (query: any, callback: any) {
        const url = this.getUrl(query);

        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            /*
            Monkeypatch `scrollToOption` to not do anything until `callback` is called
            to prevent scrolling to top when next URL loads.
            Reference issue: https://github.com/orchidjs/tom-select/issues/556
            */
            const _scrollToOption = this.scrollToOption;
            this.scrollToOption = () => {};

            if (json.page_number !== json.total_pages) {
              const nextPage = json.page_number + 1;
              const term = encodeURIComponent(query);
              const nextUrl = `${remoteUrl}?${queryField}=${term}&page=${nextPage}`;
              this.setNextUrl(query, nextUrl);
            }

            callback(json.entries);
            this.scrollToOption = _scrollToOption;
          })
          .catch(() => {
            callback();
          });
      },
      preload: true,
    };
  },
  createSettings(this: any, dataset: any): Partial<TomSettings> {
    const {
      labelField,
      valueField,
      options,
      dropdownParent,
      maxOptions,
      optgroups,
      remoteUrl,
      queryField,
    } = dataset;
    // HTML escape option label only when htmlEscape key exists in dataset since it's a boolean value
    const htmlEscape = dataset.hasOwnProperty("htmlEscape");
    const plugins = ["dropdown_input", "no_backspace_delete", "remove_button"];
    if (remoteUrl) plugins.push("virtual_scroll");

    const settings: any = {
      labelField: labelField || "label",
      valueField: valueField || "value",
      searchField: [labelField || "label"],
      allowEmptyOption: true,
      plugins: plugins,
      options: JSON.parse(options),
      dropdownParent: dropdownParent,
      maxOptions: maxOptions || null,
      optgroups: optgroups ? JSON.parse(optgroups) : [],
      loadingClass: "ts-loading",
      render: {
        option: function (data: any, escape: any) {
          const html = htmlEscape
            ? escape(data[settings.labelField])
            : data[settings.labelField];

          if (data.hide) return "<div style='display: none;'></div>";
          return "<div>" + html + "</div>";
        },
        item: function (data: any, escape: any) {
          const html = htmlEscape
            ? escape(data[settings.labelField])
            : data[settings.labelField];
          return "<div>" + html + "</div>";
        },
      },
      onInitialize: function () {
        // Stop escape key from propagating to prevent closing modal
        this.control.addEventListener("keyup", (event: any) => {
          if (event.key == "Escape") event.stopPropagation();
        });

        // Stop input event from propagating to prevent triggering form's `phx-change`
        this.control_input.addEventListener("input", (event: any) => {
          event.stopPropagation();
        });
        this.control_input.addEventListener("change", (event: any) => {
          event.stopPropagation();
        });
      },
    };

    if (remoteUrl) {
      const remoteSettings = {
        ...settings,
        ...this.remoteSettings(remoteUrl, queryField),
      };
      // Delete options so it will use the default derived from select input
      delete remoteSettings.options;

      return remoteSettings;
    }
    return settings;
  },
  createTomSelect(this: any) {
    // Remove readonly attribute to make it editable by TomSelect
    if (this.el.readOnly) {
      this.el.readOnly = false;
    }

    new TomSelect(this.el, this.createSettings(this.el.dataset));
  },
  mounted(this: any) {
    if (this.el.tomselect) return;
    this.createTomSelect();
    this.el.addEventListener("openDropdown", () => {
      this.el.tomselect.open();
    });
    this.el.addEventListener("removeItem", (e: any) => {
      this.el.tomselect.removeItem(e.detail.value);
    });
  },
  getSelectValues(select: any) {
    return [...select.options]
      .filter((opt: any) => opt.selected)
      .map((opt: any) => opt.value || opt.text);
  },
  updated(this: any) {
    // Sync tomselect in case select changes value
    // this.el.tomselect.sync();
    // Get tomselect value after sync
    // const val = this.el.tomselect.getValue();
    const values = this.getSelectValues(this.el);
    // Destroy tomselect
    this.el.tomselect.destroy();
    // Create new tomselect instance
    this.createTomSelect();
    // Set options to whatever is set in `data-options` attribute
    // This is useful for manually setting options when options are from remote source
    this.el.tomselect.addOptions(
      JSON.parse(this.el.tomselect.input.dataset.options),
    );
    // Set the value from the destroyed tomselect in silent mode so
    // it will not fire change event
    this.el.tomselect.setValue(values, true);
  },
};

Hooks.DatePicker = {
  mounted(this: any) {
    this.el.addEventListener("showPicker", () => {
      this.el.showPicker();
    });
  },
};

Hooks.Filter = {
  // `this.hide` and `this.show` uses execJS to maintain added/remove attrs
  // even after LV update
  hide(element: HTMLElement) {
    window.liveSocket.execJS(
      element,
      // JS encoded from Phoenix.LiveView.JS `JS.add_class("hidden") |> JS.add_attribute({"aria-hidden", "true"})`
      '[["add_class",{"names":["hidden"],"time":200,"to":null,"transition":[[],[],[]]}],["set_attr",{"attr":["aria-hidden","true"],"to":null}]]',
    );
  },
  show(element: HTMLElement) {
    window.liveSocket.execJS(
      element,
      // JS encoded from Phoenix.LiveView.JS `JS.remove_class("hidden") |> JS.remove_attribute("aria-hidden")`
      '[["remove_class",{"names":["hidden"],"time":200,"to":null,"transition":[[],[],[]]}],["remove_attr",{"attr":"aria-hidden","to":null}]]',
    );
  },
  // Hide or show inputs based on whether the search term matches any labels within it
  renderInputs(this: any, searchTerm: string) {
    this.el
      .querySelectorAll("[data-filter-input]")
      .forEach((filterInput: HTMLElement) => {
        let contents: Array<string> = [];
        filterInput.querySelectorAll("label").forEach((el: any) => {
          contents.push(el.textContent);
        });

        if (
          contents.some((content: string) =>
            content.toLocaleLowerCase().includes(searchTerm.toLowerCase()),
          )
        ) {
          this.show(filterInput);
        } else {
          this.hide(filterInput);
        }
      });
  },
  // Hide accordion headings & body when all inputs are hidden, otherwise show
  renderAccordionItems(this: any) {
    this.el
      .querySelectorAll('[aria-labelledby*="collapse-heading"]')
      .forEach((accordionBody: any) => {
        let hiddenInputs: any = [];

        const accordionContainer: any = document.getElementById(
          accordionBody.getAttribute("data-container"),
        );

        accordionBody
          .querySelectorAll("[data-filter-input]")
          .forEach((filterInput: Element) => {
            hiddenInputs.push(filterInput.getAttribute("aria-hidden"));
          });

        if (hiddenInputs.every((el: any) => el === "true")) {
          this.hide(accordionContainer);
        } else {
          this.show(accordionContainer);
        }
      });
  },
  updateFilterCount(this: any) {
    const filterCounter: any = document.querySelector("[data-filter-count]");
    const filterCount = this.el.querySelectorAll("[data-filter-active]").length;
    if (!filterCounter) {
      return;
    }

    if (filterCount > 0) {
      filterCounter.textContent = filterCount;
      filterCounter.style.display = "block";
    } else {
      filterCounter.textContent = "";
      filterCounter.style.display = "none";
    }
  },
  mounted(this: any) {
    this.handleEvent("search", (data: { term: string }) => {
      this.renderInputs(data.term);
      this.renderAccordionItems();
    });
    this.updateFilterCount();
  },
  updated(this: any) {
    this.updateFilterCount();
  },
};

/*
Make a list searchable by hiding items that don't match the search term
How to use:
1. Add the hook to the parent/ancestor of the list you want to search
2. Add a data-search-input attr to the same element which should contain the id of the search input
3. Optional: Add data-search-display attr to the same element to set the display value of the items when shown.
Default is "block".
4. Optional: Add data-search-list-parent-selector attr to the same element to select any descendant as the parent of the list.
Default is the element itself.
5. Optional: You can clear the search by dispatching a custom event "clear-search" on the search input.

Example:
<.text_input id="search-input" ... />

<ul id="my-list" phx-hook="Search" data-search-input="search-input" data-search-display="flex">
  <li>Item 1</li>
  <li>Item 2</li>
  <li>Item 3</li>
</ul>

<table id="my-table" phx-hook="Search" data-search-input="search-input" data-search-list-parent-selector="tbody">
  <thead>...</thead>
  <tbody>
    <tr>
      <td>Item 1</td>
      <td>Item 2</td>
    </tr>
    ...
  </tbody>
</table>

<button phx-click={JS.dispatch("clear-search", to: "#search-input")}>Clear Search</button>
*/
Hooks.Search = {
  searchTerm: "",
  searchInput: null,
  listParent: null,
  display: "block",
  runChildrenCallback(parent: any, attribute: string) {
    parent.querySelectorAll(`[${attribute}]`).forEach((el: any) => {
      window.liveSocket.execJS(el, el.getAttribute(`${attribute}`));
    });
  },
  toggleItems(this: any, searchTerm: string) {
    const listParent = this.listParent;
    const listParentChildren = listParent.children;
    const display = this.display;
    // NodeList is not true array, so convert it to array to use forEach
    const children = Array.from(listParentChildren);

    children.forEach((child: any) => {
      const childText = child.textContent.toLowerCase();
      if (childText.includes(searchTerm.toLowerCase())) {
        // JS encoded from Phoenix.LiveView.JS `JS.show(display: display)`
        window.liveSocket.execJS(
          child,
          `[["show", {"display": "${display}"}]]`,
        );
        this.runChildrenCallback(child, "data-search-on-display");
      } else {
        // JS encoded from Phoenix.LiveView.JS `JS.hide()`
        window.liveSocket.execJS(child, `[["hide", {}]]`);
        this.runChildrenCallback(child, "data-search-on-hide");
      }
    });
  },
  mounted(this: any) {
    const searchInputId = this.el.getAttribute("data-search-input");
    const searchInput = <HTMLInputElement>(
      document.getElementById(searchInputId)
    );
    this.searchInput = searchInput;

    const listParentSelector = this.el.getAttribute(
      "data-search-list-parent-selector",
    );
    const listParent = this.el.querySelector(listParentSelector);
    this.listParent = listParent || this.el;

    const display = this.el.getAttribute("data-search-display") || "block";
    this.display = display;

    searchInput &&
      searchInput.addEventListener("input", (e: any) => {
        const searchTerm = e.target.value;
        this.searchTerm = searchTerm;
        this.toggleItems(searchTerm);
      });

    searchInput &&
      searchInput.addEventListener("clear-search", () => {
        searchInput.value = "";
        searchInput.dispatchEvent(new Event("input", { bubbles: true }));
      });
  },
  updated(this: any) {
    if (this.searchInput) this.searchInput.value = this.searchTerm;
    this.toggleItems(this.searchTerm);
  },
};

Hooks.EditorJS = {
  mounted(this: any) {
    const inputHTML = <any>document.getElementById(this.el.dataset.inputId);
    const parser = editorParser();

    const editor = new EditorJS({
      holder: this.el.id,
      minHeight: 0,
      tools: {
        header: Header,
        list: {
          class: <any>List,
          inlineToolbar: true,
        },
      },
      data: JSON.parse("{}"),
      onReady: () => {
        if (inputHTML.value) editor.blocks.renderFromHTML(inputHTML.value);
      },
      onChange: () => {
        editor.save().then((outputData) => {
          const htmlData = parser.parse(outputData);

          if (htmlData.length === 0) {
            inputHTML.value = "";
          } else {
            inputHTML.value = htmlData.join("");
          }
        });
      },
    });
  },
};

export default Hooks;
