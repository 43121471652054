import TomSelect from "tom-select";
import { Loader as GoogleMapsLoader } from "@googlemaps/js-api-loader";
import { closeSidebar } from "../../martide/sidebar";
import { log } from "console";

interface Embarkation {
  company: string | null;
  vessel_name: string | null;
  rank: string | null;
  start_at: string | null;
  end_at: string | null;
}

interface Candidate {
  id: string | null;
  application_date: string | null;
  avatar: string | null;
  email: string | null;
  ex_crew: boolean;
  full_name: string | null;
  home_phone: string | null;
  initials: string | null;
  manning_agent_initials: string | null;
  manning_agent_name: string | null;
  manning_agent_logo: string | null;
  mobile_phone: string | null;
  rank: string | null;
  readiness_date: string | null;
  vessel_id: string | null;
  embarkations: Embarkation[];
  profile_path: string;
}

interface Location {
  id: string;
  candidates: Candidate[];
  lat: number;
  lon: number;
  heading: number;
  vessel_id: string;
  vessel_initials: string;
  vessel_name: string;
  vessel_photo: string | null;
  vessel_type: string | null;
}

interface LocationOption {
  title: string;
  id: string;
  location_id: string;
}

// Renders an avatar image or initials
function avatar(
  avatar: string | null,
  alt: string | null,
  initials: string | null,
) {
  if (avatar) {
    return `
      <img
        src=${avatar}
        class="w-10 h-10 rounded-full object-cover"
        alt=${alt || ""}
        loading="lazy"
      />
    `;
  } else {
    return `
      <div class="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">${initials || ""}</span>
      </div>
    `;
  }
}

function updateURL(locationId: string | null) {
  const url: URL = new URL(window.location.href);

  if (locationId) {
    url.searchParams.set("id", locationId);
  } else {
    url.searchParams.delete("id");
  }

  window.history.pushState({}, "", url.toString());
}

const VesselLocation = {
  mounted(this: any) {
    // Clear selection on escape key press
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        this.clearSelection();
      }
    });

    closeSidebar();

    // Initialize Google Maps loader with API key
    const loader = new GoogleMapsLoader({
      apiKey: this.el.dataset.apikey,
      version: "weekly",
    });

    // State management for map, markers, selection and info panels
    this.map = null;
    this.markers = [];
    this.select = null;
    this.selectedVessel = null;
    this.selectedAddress = null;
    this.selectedCandidate = null;
    this.infoHeaderDiv = document.createElement("div");
    this.infoBodyDiv = document.createElement("div");

    this.infoHeaderDiv.classList.add("mt-2");

    this.createSearchControl = () => {
      const locationOptions = JSON.parse(this.el.dataset.locationOptions);
      const candidateOptions = JSON.parse(this.el.dataset.candidateOptions);

      // Create the control div
      const controlDiv = document.createElement("div");
      controlDiv.classList.add("w-96", "flex", "flex-col");

      // Create wrapper div for search and close button with white background
      const searchWrapper = document.createElement("div");
      searchWrapper.classList.add(
        "flex",
        "flex-col",
        "gap-2",
        "p-3",
        "pb-2",
        "bg-white",
        "z-10",
        "rounded-t",
        "relative",
      );

      // Create search controls wrapper with padding for close button
      const searchControlsWrapper = document.createElement("div");
      searchControlsWrapper.classList.add("flex", "flex-col", "gap-2");
      this.searchControlsWrapper = searchControlsWrapper;

      // Create vessel search div
      const vesselSearchDiv = document.createElement("div");
      vesselSearchDiv.classList.add("bg-white", "flex-1");

      // Create candidate search div
      const candidateSearchDiv = document.createElement("div");
      candidateSearchDiv.classList.add("bg-white", "flex-1");

      // Create close button
      const closeButton = document.createElement("button");
      closeButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      `;
      closeButton.classList.add(
        "p-1.5",
        "hover:bg-gray-100",
        "rounded-full",
        "focus:outline-none",
        "flex-shrink-0",
        "hidden",
        "transition-colors",
        "absolute",
        "right-3",
        "top-3",
      );
      closeButton.addEventListener("click", () => {
        this.clearSelection();
      });

      // Add vessel select element
      const vesselSelectElement = document.createElement("select");
      vesselSelectElement.classList.add("w-full", "font-sans");
      vesselSearchDiv.appendChild(vesselSelectElement);

      // Add candidate select element
      const candidateSelectElement = document.createElement("select");
      candidateSelectElement.classList.add("w-full", "font-sans");
      candidateSearchDiv.appendChild(candidateSelectElement);

      // Add elements to search controls wrapper
      searchControlsWrapper.appendChild(vesselSearchDiv);
      searchControlsWrapper.appendChild(candidateSearchDiv);

      // Add elements to wrapper
      searchWrapper.appendChild(searchControlsWrapper);
      searchWrapper.appendChild(closeButton);

      // Create content container
      const contentContainer = document.createElement("div");
      contentContainer.classList.add(
        "overflow-y-auto",
        "max-h-[calc(100vh-120px)]",
        "bg-white",
        "shadow-md",
        "rounded-b",
        "p-4",
        "pb-16",
      );

      contentContainer.appendChild(this.infoHeaderDiv);
      contentContainer.appendChild(this.infoBodyDiv);

      // Add elements to control div
      controlDiv.appendChild(searchWrapper);
      controlDiv.appendChild(contentContainer);

      this.closeButton = closeButton;

      // Add the custom control to the map
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

      // Initialize vessel TomSelect
      this.vesselSelect = new TomSelect(vesselSelectElement, {
        create: false,
        hideSelected: true,
        maxItems: 1,
        options: locationOptions,
        placeholder: "Vessels",
        valueField: "id",
        labelField: "title",
        searchField: ["title"],
        sortField: "title",
      });

      // Initialize candidate TomSelect
      this.candidateSelect = new TomSelect(candidateSelectElement, {
        create: false,
        hideSelected: true,
        maxItems: 1,
        options: candidateOptions,
        placeholder: "Seafarers",
        valueField: "id",
        labelField: "title",
        searchField: ["title"],
        sortField: "title",
      });

      // Handle vessel selection change
      this.vesselSelect.on("change", (value: any) => {
        this.vesselSelect.blur();
        this.candidateSelect.clear(true);
        this.pushEvent("select_changed", { type: "vessel", id: value });
      });

      // Handle candidate selection change
      this.candidateSelect.on("change", (value: any) => {
        this.candidateSelect.blur();

        const selectedVessel = this.vesselSelect.getValue();
        let sameVessel = false;

        if (this.selectedLocation) {
          this.selectedLocation.candidates.forEach((candidate: Candidate) => {
            if (candidate.id === value) {
              sameVessel = true;
            }
          });
        }

        if (!sameVessel) {
          this.vesselSelect.clear(true);
        }

        this.pushEvent("select_changed", { type: "candidate", id: value });
      });
    };

    this.updateSearchControl = () => {
      // Updates the search control and info panels based on selection state
      // Handles three states:
      // 1. Selected candidate details
      // 2. Selected vessel details
      // 3. No selection

      const locationOptions = JSON.parse(this.el.dataset.locationOptions);
      const candidateOptions = JSON.parse(this.el.dataset.candidateOptions);

      // Show/hide close button and adjust padding based on selection state
      if (this.closeButton && this.searchControlsWrapper) {
        if (this.selectedLocation || this.selectedCandidate) {
          this.closeButton.classList.remove("hidden");
          this.searchControlsWrapper.classList.add("pr-10");
        } else {
          this.closeButton.classList.add("hidden");
          this.searchControlsWrapper.classList.remove("pr-10");
        }
      }

      // Update vessel select options
      if (this.vesselSelect) {
        this.vesselSelect.clearOptions();
        this.vesselSelect.addOptions(locationOptions);
      }

      // Update candidate select options
      if (this.candidateSelect) {
        this.candidateSelect.clearOptions();
        this.candidateSelect.addOptions(candidateOptions);
      }

      if (this.selectedCandidate) {
        let exCrew = "";

        if (this.selectedCandidate.ex_crew) {
          exCrew = `
            <div class="flex justify-center">
              <span class="px-4 py-2 text-sm font-medium text-green-700 bg-green-100 rounded-full">
                Ex-Crew
              </span>
            </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg p-6 space-y-4">
            <div id="back-to-vessel-btn" class="text-blue-600 text-sm font-semibold cursor-pointer">
              &larr; Back to Vessel
            </div>

            <div class="flex flex-col items-center text-center space-y-2">
              ${avatar(this.selectedCandidate.avatar, this.selectedCandidate.full_name, this.selectedCandidate.initials)}
              <a href="${this.selectedCandidate.profile_path}" target="_blank" class="text-lg font-bold text-gray-800 hover:text-blue-600">${this.selectedCandidate.full_name || ""}</a>
              <p class="text-sm font-semibold text-gray-500 uppercase">${this.selectedCandidate.rank || ""}</p>
            </div>

            <div class="text-sm space-y-1 text-gray-700">
              <p>Email:
                ${this.selectedCandidate.email ? `<a href="mailto:${this.selectedCandidate.email}" target="_blank" class="font-medium">${this.selectedCandidate.email}</a>` : ""}
              </p>
              <p>Mobile:
                ${this.selectedCandidate.mobile_phone ? `<a href="tel:${this.selectedCandidate.mobile_phone}" target="_blank" class="font-medium">${this.selectedCandidate.mobile_phone}</a>` : ""}
              </p>
              <p>Home:
                ${this.selectedCandidate.home_phone ? `<a href="tel:${this.selectedCandidate.home_phone}" target="_blank" class="font-medium">${this.selectedCandidate.home_phone}</a>` : ""}
              </p>
              <p>Readiness:
                ${this.selectedCandidate.readiness_date ? `<span class="font-medium">${this.selectedCandidate.readiness_date}</span>` : ""}
              </p>
              <p>Application:
                ${this.selectedCandidate.application_date ? `<span class="font-medium">${this.selectedCandidate.application_date}</span>` : ""}
              </p>
            </div>

            ${exCrew}

          </div>
        `;

        const backBtn = document.getElementById("back-to-vessel-btn");

        if (backBtn) {
          backBtn.addEventListener("click", () => {
            this.selectedCandidate = null;
            this.candidateSelect.clear(true);
            this.updateSearchControl();
          });
        }

        const embarkations = this.selectedCandidate.embarkations
          .map((embarkation: Embarkation) => {
            return `
            <div class="text-sm text-gray-700">
              <p class="font-semibold">${embarkation.company || ""}</p>
              <p class="text-gray-500">Rank: ${embarkation.rank || ""}</p>
              <p class="text-gray-500">Period: ${embarkation.start_at || ""} - ${embarkation.end_at || ""}</p>
            </div>
          `;
          })
          .join("");

        this.infoBodyDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg p-6 space-y-4">
            <!-- Manning agent and Positions -->
            <div class="space-y-4">
              <div>
                <p class="text-sm font-bold text-gray-800">Manning Agent</p>
                <p class="flex items-center space-x-2 text-sm text-gray-700">
                  ${avatar(this.selectedCandidate.manning_agent_logo, this.selectedCandidate.manning_agent_name, this.selectedCandidate.manning_agent_initials)}
                  <span>${this.selectedCandidate.manning_agent_name || ""}</span>
                </p>
              </div>

              <div>
                <p class="text-sm font-bold text-gray-800 mb-4">Last 5 Employments</p>
                <div class="flex flex-col space-y-4">
                  ${embarkations}
                </div>
              </div>
            </div>
          </div>
      `;
      } else if (this.selectedLocation) {
        // Reverse geocode vessel location to get address
        loader.importLibrary("geocoding").then(({ Geocoder }) => {
          const latlng = {
            lat: this.selectedLocation?.lat ?? 0,
            lng: this.selectedLocation?.lon ?? 0,
          };
          const geocoder = new Geocoder();

          geocoder
            .geocode({ location: latlng })
            .then((response) => {
              const { results } = response;
              if (Array.isArray(results)) {
                const result = results.at(-2) || results.at(-1);
                if (result) {
                  this.selectedAddress = result.formatted_address;
                }
              }
            })
            .catch((error) => {
              console.error("Geocoding failed:", error);
            });
        });

        let vesselPhoto = null;

        if (this.selectedLocation.vessel_photo) {
          vesselPhoto = `
            <img
              src=${this.selectedLocation.vessel_photo}
              class="w-90 h-60 rounded-lg my-5"
              alt=${this.selectedLocation.vessel_name}
              loading="lazy"
            />
          `;
        } else {
          vesselPhoto = `
            <div class="relative inline-flex h-60 w-96 items-center justify-center overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-600 my-5">
              <span class="font-medium text-gray-600 dark:text-gray-300">${this.selectedLocation.vessel_initials}</span>
              </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div>
            <h3 class="m-0 text-xl">
              <a href="${this.selectedLocation.vessel_path}" target="_blank">
                ${this.selectedLocation.vessel_name}
              </a>
            </h3>
            <p class="m-0 text-sm text-gray-500">${this.selectedLocation.vessel_type}</p>
            ${vesselPhoto}
            <div class="flex items-center">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <h2 class="text-base font-medium">${this.selectedAddress || ""}</h2>
                <p class="text-sm text-gray-500">${this.selectedLocation.lat}, ${this.selectedLocation.lon}</p>
              </div>
            </div>
            <div class="flex items-center mt-5">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <p class="text-base font-medium">${this.selectedLocation.candidates.length} seafarers onboard</p>
              </div>
            </div>
          </div>
        `;

        this.infoBodyDiv.innerHTML = `
          <div class="border-t border-gray-300 mt-5">
            ${this.selectedLocation.candidates
              .map((candidate: Candidate) => {
                return `
                  <div class="flex items-center space-x-4 py-4 candidate-list-item cursor-pointer" data-candidate-id="${candidate.id}">
                    ${avatar(candidate.avatar, candidate.full_name, candidate.initials)}
                    <div>
                      <p class="font-semibold text-blue-500 uppercase hover:text-blue-600">${candidate.rank || ""}</p>
                      <p class="font-bold text-gray-800 hover:text-blue-600">${candidate.full_name || ""}</p>
                    </div>
                  </div>
                `;
              })
              .join("")}
          </div>
        `;

        // Then attach event listeners to elements within infoBodyDiv
        const candidateElements = this.infoBodyDiv.querySelectorAll(
          ".candidate-list-item",
        );
        candidateElements.forEach((el: any) => {
          el.addEventListener("click", () => {
            const candidateId = el.dataset.candidateId;

            const candidate = this.selectedLocation.candidates.find(
              (candidate: Candidate) => candidate.id === candidateId,
            );

            if (candidate) {
              this.selectedCandidate = candidate;
              this.candidateSelect.addItem(candidate.id, true);
            } else {
              this.selectedCandidate = null;
            }

            this.updateSearchControl();
          });
        });
      } else {
        this.infoHeaderDiv.innerHTML = null;
        this.infoBodyDiv.innerHTML = null;
      }
    };

    this.updateMarkers = () => {
      // Remove all markers from the map
      this.markers.forEach((marker: any) => {
        marker.map = null;
      });

      const locations = JSON.parse(this.el.dataset.locations);

      loader.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
        locations.forEach(
          ({ id, lat, lon, heading, vessel_name }: Location) => {
            const content = new DOMParser().parseFromString(
              `
              <div class="relative group">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" fill="#fff" fill-rule="evenodd">
                  <path
                    style="fill: ${this.selectedLocation && this.selectedLocation.id === id ? "#22c55e" : "#3b82f6"}; stroke: ${this.selectedLocation && this.selectedLocation.id === id ? "#22c55e" : "#3b82f6"};"
                    d="M20 20L10 0L0 20l10-5z"
                    transform="rotate(${heading} 10 10)" />
                </svg>
                <div class="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 ${this.selectedLocation && this.selectedLocation.id === id ? "block" : "hidden group-hover:block"} bg-white p-2 rounded shadow-lg">
                  <p class="whitespace-nowrap">${vessel_name}</p>
                </div>
              </div>
            `,
              "text/html",
            ).documentElement;

            const marker = new AdvancedMarkerElement({
              map: this.map,
              position: { lat: lat, lng: lon },
              title: vessel_name,
              content: content,
              gmpClickable: true,
            });

            // Handle marker click to select vessel
            marker.addListener("click", () => {
              this.selectedLocation = null;
              this.selectedAddress = null;
              this.selectedCandidate = null;
              this.pushEvent("select_changed", { type: "vessel", id: id });
            });

            this.markers.push(marker);
          },
        );
      });
    };

    this.handleEvent("select_location", (location: Location) => {
      if (location.id) {
        this.selectedLocation = location;
        updateURL(location.id);
      } else {
        this.selectedLocation = null;
        updateURL(null);
      }

      this.updated();

      if (this.selectedLocation) {
        this.vesselSelect.addItem(this.selectedLocation.id, true);
        this.map.panTo({
          lat: this.selectedLocation.lat,
          lng: this.selectedLocation.lon,
        });
      }
    });

    this.handleEvent(
      "select_candidate",
      ({
        candidate,
        location,
      }: {
        candidate: Candidate;
        location: Location;
      }) => {
        this.selectedCandidate = candidate;
        this.selectedLocation = location;
        updateURL(location.id);
        this.updated();

        this.candidateSelect.addItem(this.selectedCandidate.id, true);
        this.vesselSelect.addItem(location.id, true);

        this.map.panTo({
          lat: location.lat,
          lng: location.lon,
        });
      },
    );

    this.handleEvent("options_loaded", () => {
      loader.importLibrary("maps").then(({ Map }) => {
        this.map = new Map(this.el, {
          mapId: "FLEETMAP",
          cameraControl: false,
          center: { lat: 0, lng: 0 },
          zoom: 4,
          maxZoom: 14,
          minZoom: 4,
          gestureHandling: "greedy",
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
          headingInteractionEnabled: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
        });

        this.createSearchControl();
        this.pushEvent("map_loaded", {});
      });

      this.updateSearchControl();
      this.updateMarkers();
    });
  },

  updated(this: any) {
    this.updateSearchControl();
    this.updateMarkers();
  },

  clearSelection(this: any) {
    this.selectedLocation = null;
    this.selectedAddress = null;
    this.selectedCandidate = null;
    this.vesselSelect.clear();
    this.candidateSelect.clear();
    updateURL(null);
    this.pushEvent("select_changed", { id: null });
  },
};

export default VesselLocation;
