// This file is from flowbite-application-ui-v1.1.1/src/sidebar.js
// @ts-nocheck
import TomSelect from "tom-select";

const isSidebarExpanded = (toggleSidebarEl) => {
  return toggleSidebarEl.getAttribute("aria-expanded") === "true"
    ? true
    : false;
};

const toggleSidebar = (sidebarEl, expand, setExpanded = false) => {
  const bottomMenuEl = document.querySelector("[sidebar-bottom-menu]");
  const mainContentEl = document.getElementById("main-content");
  if (expand) {
    sidebarEl.classList.add("lg:w-64");
    sidebarEl.classList.remove("lg:w-16");
    mainContentEl.classList.add("lg:ml-64");
    mainContentEl.classList.remove("lg:ml-16");

    document
      .querySelectorAll(
        "#" + sidebarEl.getAttribute("id") + " [sidebar-toggle-item]",
      )
      .forEach((sidebarToggleEl) => {
        sidebarToggleEl.classList.remove("lg:hidden");
        sidebarToggleEl.classList.remove("lg:absolute");
      });

    // toggle multi level menu item initial and full text
    document
      .querySelectorAll(
        "#" + sidebar.getAttribute("id") + " ul > li > ul > li > a",
      )
      .forEach((e) => {
        e.classList.add("pl-11");
        e.classList.remove("px-4");
        e.childNodes[0].classList.remove("hidden");
        e.childNodes[1].classList.add("hidden");
      });

    bottomMenuEl.classList.remove("flex-col", "space-y-4", "p-2");
    bottomMenuEl.classList.add("space-x-4", "p-4");
    setExpanded ? toggleSidebarEl.setAttribute("aria-expanded", "true") : null;
  } else {
    sidebarEl.classList.remove("lg:w-64");
    sidebarEl.classList.add("lg:w-16");
    mainContentEl.classList.remove("lg:ml-64");
    mainContentEl.classList.add("lg:ml-16");
    document
      .querySelectorAll(
        "#" + sidebarEl.getAttribute("id") + " [sidebar-toggle-item]",
      )
      .forEach((sidebarToggleEl) => {
        sidebarToggleEl.classList.add("lg:hidden");
        sidebarToggleEl.classList.add("lg:absolute");
      });

    // toggle multi level menu item initial and full text
    document
      .querySelectorAll(
        "#" + sidebar.getAttribute("id") + " ul > li > ul > li > a",
      )
      .forEach((e) => {
        e.classList.remove("pl-11");
        e.classList.add("px-4");
        e.childNodes[0].classList.add("hidden");
        e.childNodes[1].classList.remove("hidden");
      });

    bottomMenuEl.classList.add("flex-col", "space-y-4", "p-2");
    bottomMenuEl.classList.remove("space-x-4", "p-4");
    setExpanded ? toggleSidebarEl.setAttribute("aria-expanded", "false") : null;
  }
};

const toggleSidebarEl = document.getElementById("toggleSidebar");
const sidebar = document.getElementById("sidebar");

if (sidebar && toggleSidebarEl) {
  document
    .querySelectorAll(
      "#" + sidebar.getAttribute("id") + " ul > li > ul > li > a",
    )
    .forEach((e) => {
      var fullText = e.textContent;
      var firstLetter = fullText.substring(0, 1);

      var fullTextEl = document.createElement("span");
      var firstLetterEl = document.createElement("span");
      firstLetterEl.classList.add("hidden");
      fullTextEl.textContent = fullText;
      firstLetterEl.textContent = firstLetter;

      e.textContent = "";
      e.appendChild(fullTextEl);
      e.appendChild(firstLetterEl);
    });

  // initialize sidebar
  if (localStorage.getItem("sidebarExpanded") !== null) {
    if (localStorage.getItem("sidebarExpanded") === "true") {
      toggleSidebar(sidebar, true, false);
    } else {
      toggleSidebar(sidebar, false, true);
    }
  }

  toggleSidebarEl.addEventListener("click", () => {
    localStorage.setItem(
      "sidebarExpanded",
      !isSidebarExpanded(toggleSidebarEl),
    );
    toggleSidebar(sidebar, !isSidebarExpanded(toggleSidebarEl), true);
  });

  sidebar.addEventListener("mouseenter", () => {
    if (!isSidebarExpanded(toggleSidebarEl)) {
      toggleSidebar(sidebar, true);
    }
  });

  sidebar.addEventListener("mouseleave", () => {
    if (!isSidebarExpanded(toggleSidebarEl)) {
      toggleSidebar(sidebar, false);
    }
  });

  const toggleSidebarMobile = (
    sidebar,
    sidebarBackdrop,
    toggleSidebarMobileHamburger,
    toggleSidebarMobileClose,
  ) => {
    sidebar.classList.toggle("hidden");
    sidebarBackdrop.classList.toggle("hidden");
    toggleSidebarMobileHamburger.classList.toggle("hidden");
    toggleSidebarMobileClose.classList.toggle("hidden");
  };

  const toggleSidebarMobileEl = document.getElementById("toggleSidebarMobile");
  const sidebarBackdrop = document.getElementById("sidebarBackdrop");
  const toggleSidebarMobileHamburger = document.getElementById(
    "toggleSidebarMobileHamburger",
  );
  const toggleSidebarMobileClose = document.getElementById(
    "toggleSidebarMobileClose",
  );

  toggleSidebarMobileEl.addEventListener("click", () => {
    toggleSidebarMobile(
      sidebar,
      sidebarBackdrop,
      toggleSidebarMobileHamburger,
      toggleSidebarMobileClose,
    );
  });

  sidebarBackdrop.addEventListener("click", () => {
    toggleSidebarMobile(
      sidebar,
      sidebarBackdrop,
      toggleSidebarMobileHamburger,
      toggleSidebarMobileClose,
    );
  });
}

const clientSelect = document.getElementById("view_as_client_form_client_id");

if (clientSelect) {
  const plugins = ["dropdown_input", "no_backspace_delete"];

  new TomSelect(clientSelect, {
    labelField: "label",
    valueField: "value",
    searchField: ["label"],
    plugins: plugins,
    onChange: function (value: string) {
      window.location.href = value;
    },
    render: {
      item: function (data: any, escape: any) {
        return `<div class="flex items-center">
        <div class="h-6 w-6">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
          </svg>
        </div>
        <span class="flex-1 ml-3 whitespace-nowrap" sidebar-toggle-item>
          ${escape(data.label)}
        </span>
      </div>`;
      },
    },
  });
}

document.querySelectorAll("[sidebar-subitem]").forEach((element: any) => {
  element.addEventListener("click", (e: any) => {
    const target = document.querySelector(element.hash);

    if (!target) return;

    e.preventDefault();

    const sectionGaps = 16;
    const headerOffset = 64 + sectionGaps;
    const currentPosition = document.body.getBoundingClientRect().top;
    const targetPosition = target.getBoundingClientRect().top;
    const offsetPosition = targetPosition - currentPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  });
});

const closeSidebar = () => {
  const sidebar = document.getElementById("sidebar");

  if (sidebar) {
    if (localStorage.getItem("sidebarExpanded") !== null) {
      toggleSidebar(sidebar, false);
    }
  }
};

export { closeSidebar };
